// import React from 'react';
import Loadable from 'react-loadable';
import { createRouter } from '@/utils/router';
import Loading from '@/components/Loading';
import App from './App';

export default createRouter({
  getComponent: () => App,
  onEnter: async (nextState, history, cb) => {
    cb();
  },
  getChildRoutes: () => [
    {
      path: '/csr',
      component: Loadable({
        loader: () => import('./csr'),
        loading: () => <Loading />
      })
    },
    {
      path: '/aboutus',
      component: Loadable({
        loader: () => import('./aboutus'),
        loading: () => <Loading />
      })
    },
    {
      path: '/pdtserve',
      component: Loadable({
        loader: () => import('./pdtserve'),
        loading: () => <Loading />
      })
    },
    {
      path: '/case',
      component: Loadable({
        loader: () => import('./case'),
        loading: () => <Loading />
      })
    },
    {
      path: '/partners',
      component: Loadable({
        loader: () => import('./partners'),
        loading: () => <Loading />
      })
    },
    {
      path: '/news',
      component: Loadable({
        loader: () => import('./news'),
        loading: () => <Loading />
      })
    },
    {
      path: '/newsdetail',
      component: Loadable({
        loader: () => import('./newsdetail'),
        loading: () => <Loading />
      })
    },
    {
      path: '/dynamic',
      component: Loadable({
        loader: () => import('./dynamic'),
        loading: () => <Loading />
      })
    },
    {
      path: '/important',
      component: Loadable({
        loader: () => import('./important'),
        loading: () => <Loading />
      })
    },
    {
      path: '/product',
      component: Loadable({
        loader: () => import('./product'),
        loading: () => <Loading />
      })
    },
    {
      path: '/recruit',
      component: Loadable({
        loader: () => import('./recruit'),
        loading: () => <Loading />
      })
    },
    {
      path: '/bulletindetail',
      component: Loadable({
        loader: () => import('./bulletindetail'),
        loading: () => <Loading />
      })
    },
    {
      path: '/newForm',
      component: Loadable({
        loader: () => import('./raysForm'),
        loading: () => <Loading />
      })
    }
  ]
});
